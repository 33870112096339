import { Component, OnInit } from '@angular/core';
import {nooDataDefault} from '../shared/NooData';
import { ModalService } from '../_modal';
import { NooDataService } from './../services/noo-data.service';

@Component({
  selector: 'app-evaluation-factors',
  templateUrl: './evaluation-factors.component.html',
  styleUrls: ['./evaluation-factors.component.scss']
})
export class EvaluationFactorsComponent implements OnInit {

  nooData: any = nooDataDefault;

  constructor(private modalService: ModalService, private nooDataService: NooDataService) { }

  ngOnInit(): void {
    this.nooDataService.getValue().subscribe((value) => {
      this.nooData = value;
    });
  }

  openMOdal(id: string) {
    this.modalService.open(id);
  }

  closeMOdal(id: string) {
    this.modalService.close(id);
  }

}
