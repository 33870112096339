<div class="jumbotron">
    <div class="container">
      <h2>8 NOO Response Evaluation Templates - Introduction</h2>
      <p><strong>Section 8</strong></p>
      <p>Formal evaluation plans or scoring of offers are not required for task order source selection under the DOE ESPC IDIQ, unless required by agency-specific policies and procedures. However, many agencies use standardized evaluation forms such as FEMP’s to efficiently organize and summarize evaluators’ observations about the  ESCO’s qualifications. These templates can provide the documentation required for the contract file on the evaluation process, the government’s assessment of the strengths, weaknesses, or deficiencies in the ESCO’s submittals, and the rationale for source selection.</p>
      <p>The documents can also be useful in any debriefings that may occur. For each down-selection, the agency Contracting Officer must notify the unsuccessful contractors and offer an opportunity for a debriefing.</p>
      <p>Note that the evaluation templates and procedures in ESCO selector (and available through <a href="https://www.energy.gov/femp/resources-implementing-federal-energy-savings-performance-contracts"> FEMP’s ESPC Resources web page</a>) are not intended to circumvent or replace agency-specific templates or procedures.</p>

    </div>

  </div>

  <div class="container form-horizontal">

    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">Evaluation Criteria</h3>
      </div>
      <div class="panel-body">
        <p>The <em>ESCO Selector tool</em> generates NOO response evaluation templates in MS Word format for agencies to use in documenting their evaluations.</p>
        <ol>
          <li>The <strong><em>Individual NOO Response Evaluation Template</em></strong> is used by individual evaluators to document their observations for each ESCO, noting significant strengths, weaknesses, and significant weaknesses and deficiencies for each criterion to support their ratings.   <a  href="assets/docs/Eval Form-1-Indiv.docx" class="btn btn-success btn-xs">Download Now&raquo;</a>  </li>
          <li>The template <strong><em>Consensus Ratings for Each ESCO</em></strong> is IDENTICAL to the <strong><em>Individual NOO Response Evaluation Templates</em></strong> except for its title and references to “consensus” rather than individual ratings. This template is used to document the consensus rating for each ESCO, as determined by voting members of the agency selection board.    <a  href="assets/docs/Eval Form-2-Consensus.docx" class="btn btn-success btn-xs">Download Now&raquo;</a> </li>
          <li>The <strong><em>Consensus Evaluation Summary for All ESCOs’ Responses</em></strong> is used to summarize the consensus ratings for all ESCOs.  It succinctly documents the number of ESCOs responding, the consensus evaluation ratings by factor, and the overall consensus rating, and provides narrative to support evaluation ratings.  Note that <em>ESCO Selector</em> does not populate this summary, but an MS Word document is available for download.  <a href="assets/docs/Eval Form 3-Summary.docx" class="btn btn-success btn-xs">Download Now&raquo;</a> </li>
          <li>An MS Word version of this template information, <strong><em>Evaluation Templates Instructions and Definitions.</em></strong> <a href="assets/docs/Evaluation Forms Instructions-Definitions.docx" class="btn btn-success btn-xs">Download Now&raquo;</a></li>
        </ol>
        <hr>
        <p><a href="objectives"> Click here to refer to project objectives from the NOO at any time &raquo;</a></p>
        <p><a class="btn btn-primary btn-lg" (click)="openM('evalCriteriaChoices')" role="button">Learn more about legislation and regulations on conducting evaluations &raquo;</a></p>
        <hr>
        <p><strong>Definitions: Technical Evaluation Ratings</strong></p>
        <table class="table  table-striped table-eere">
          <td>Rating</td><td>Abbreviation</td><td>Risk Level</td><td>Definition</td>

          <tbody>
          <tr><td>Excellent</td><td>E</td><td>Very Low Risk</td><td>The response contains strengths and no deficiencies or weaknesses.  Based on information provided, there is no doubt that the ESCO demonstrates an exceptional understanding of the services required. The highest quality of performance is anticipated.</td></tr>
          <tr><td>Very Good</td><td>VG</td><td>Low Risk</td><td>The response contains strengths that outweigh any weaknesses with no deficiencies. Based on the information provided, there is little doubt that the ESCO demonstrates a high quality of understanding of the services required to meet or exceed some requirements.</td></tr>
          <tr><td>Satisfactory</td><td>S</td><td>Moderate Risk</td><td>The response contains strengths and weaknesses with no deficiencies. Based on the information provided, the ESCO demonstrates an understanding of the services required to meet requirements.</td></tr>
          <tr><td>Unsatisfactory</td><td>U</td><td>Unacceptable Risk</td><td>The response contains one or more weakness which are not offset by strengths and may contain deficiencies/or gross omissions. Based on information provided, there is significant doubt that the ESCO understands the services required.</td></tr>
          <tr><td>Neutral</td><td>N</td><td>NA</td><td>For use in evaluating past performance (only) where there is no similar past performance to evaluate, or on which to base a rating. The ESCO will be evaluated neither favorably nor unfavorably.</td></tr>
          </tbody>
          </table>
        <hr>
        <p><strong>Definitions of Strengths and Weaknesses</strong></p>
        <table class="table  table-striped table-eere">
          <tbody>
          <tr><td><strong><em>A significant strength</em></strong> is an aspect of the response that has appreciable merit and appreciably exceeds agency objectives in a way that will be appreciably advantageous to the Agency during contract performance.</td></tr>
          <tr><td><strong><em>Strength</em></strong> is an aspect of the response that has merit or exceeds agency objectives in a way that will be advantageous to the Agency during contract performance.</td></tr>
          <tr><td><strong><em>A weakness</em></strong> is a flaw in the response that increases the risk of unsuccessful contract performance.</td></tr>
          <tr><td><strong><em>A significant weakness </em></strong>in the response is a flaw that appreciably increases the risk of unsuccessful contract performance.</td></tr>
          <tr><td><strong><em>A deficiency </em></strong>means a material failure of a response to meet a Government requirement, or a combination of significant weaknesses in a response that significantly increases the risks of unsuccessful contract performance to an unacceptable level.</td></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!--Modal Text -->

  <jw-modal id="evalCriteriaChoices" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog wide">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeM('evalCriteriaChoices')"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idevalCriteriaChoices">Requirements on Documenting Source Selection Decisions</h4>
        </div>
        <div class="modal-body">
          <p>Fair opportunity to be considered should be provided to all Indefinite Delivery Indefinite Quantity (IDIQ) contract holders. </p>
          <p>Formal evaluation plans or scoring of offers are not required for task order source selection, unless required by agency specific policies and procedures.</p>
          <p>Decision documentation for orders should be included in the contract file, including the rational for source selection.</p>
          <p>For each downselection, the agency Contracting Officer shall notify the unsuccessful contractors and provide an opportunity for a debriefing.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closeM('evalCriteriaChoices')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>
