import { nooDataDefault } from './../shared/NooData';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class NooDataService {

public nooData = new BehaviorSubject(JSON.parse(JSON.stringify(nooDataDefault)));

default = JSON.parse(JSON.stringify(nooDataDefault));

  constructor() { }

  getValue(): Observable <{}> {
    return this.nooData.asObservable();
  }

  setValue(newValue): void {
    this.nooData.next(newValue);
  }

  resetValue(): void {

    this.nooData.next(JSON.parse(JSON.stringify(nooDataDefault)));
  }



}
