import { nooDataDefault } from './../shared/NooData';
import { Component, OnInit } from '@angular/core';
import { NooDataService } from './../services/noo-data.service';

@Component({
  selector: 'app-submittal-of-expressions-of-interest',
  templateUrl: './submittal-of-expressions-of-interest.component.html',
  styleUrls: ['./submittal-of-expressions-of-interest.component.scss']
})

export class SubmittalOfExpressionsOfInterestComponent implements OnInit {
  nooData: any = nooDataDefault;

  constructor(private nooDataService: NooDataService) {}

  ngOnInit(): void {
    this.nooDataService.getValue().subscribe((value) => {
      this.nooData = value;
      console.log(this.nooData);
    });
  }

  formChanged() {
    this.nooDataService.setValue(this.nooData);
  }

}
