export const generalObjectives = [
    {
    value: 0,
    label: 'Agency anticipates using the Preliminary Assessment and/or Investment Grade Audit to meet Compliance Tracking System (CTS) compliance audits and/or to contribute to meeting Energy Act of 2020 goals of implementing 50% of cost-effective ECMs through performance contracting.',
    labelValue: 'Agency anticipates using the Preliminary Assessment and/or Investment Grade Audit to meet Compliance Tracking System (CTS) compliance audits and/or to contribute to meeting Energy Act of 2020 goals of implementing 50% of cost-effective ECMs through performance contracting.',
    selected: false
    },
     {
      value: 1,
      label:
      'Increase facility energy efficiency and water efficiency for agency-wide facility energy use intensity and potable water use intensity.',
      labelValue: 'Increase facility energy efficiency and water efficiency for agency-wide facility energy use intensity and potable water use intensity.',
      selected: false,
    },
    {
      value: 2,
      label: 'Implement measures that result in grid interactive efficient buildings (GEB), including energy efficiency, on-site electricity generation, energy storage, and load flexibility technologies enabled through smart controls.',
      labelValue: 'Implement measures that result in grid interactive efficient buildings (GEB), including energy efficiency, on-site electricity generation, energy storage, and load flexibility technologies enabled through smart controls.',
      selected: false
    },
    {
      value: 3,
      label: 'Use monitoring-based commissioning to identify ECMs with savings that may vary from year to year,  and use a reserve account to capture the savings (see DOE-FEMPs “Enhancing Performance Contracts with Monitoring-Based Commissioning (MBCx),” section 4.3, Incorporating Monitoring-Based Commissioning in Each Performance Contract Phase, https://www.energy.gov/eere/femp/articles/enhancing-performance-contracts-monitoring-based-commissioning).',
      labelValue: 'Use monitoring-based commissioning to identify ECMs with savings that may vary from year to year,  and use a reserve account to capture the savings (see DOE-FEMPs “Enhancing Performance Contracts with Monitoring-Based Commissioning (MBCx),” section 4.3, Incorporating Monitoring-Based Commissioning in Each Performance Contract Phase, https://www.energy.gov/eere/femp/articles/enhancing-performance-contracts-monitoring-based-commissioning).',
      selected: false
    },

    {
      value: 4,
      label: 'Address site energy security and resiliency needs.',
      labelValue: 'Address site energy security and resiliency needs.',
      selected: false
    },
    {
      value: 5,
      label: 'Consider utilization of Energy Sales Agreement (ESA) for addressing ownership and operation of distributed energy generation assets.',
      labelValue: 'Consider utilization of Energy Sales Agreement (ESA) for addressing ownership and operation of distributed energy generation assets.',
      selected: false
    },
    {
      value: 6,
      label: 'Achieve comprehensive project and deep energy savings.',
      labelValue: 'Achieve comprehensive project and deep energy savings.',
      selected: false
    },
    {
      value: 7,
      label: 'Achieve comprehensive project and deep water savings.',
      labelValue: 'Achieve comprehensive project and deep water savings.',
      selected: false
    },
    {
      value: 8,
      label: 'Guiding principles --  Develop a successful project incorporating the <em>Guiding Principles for Federal Leadership in High Performance and Sustainable Buildings</em>:  1) Employ integrated design principles;  2) Optimize energy performance;  3) Protect and conserve water;  4) Enhance indoor environmental quality;  5) Reduce environmental impact of materials. (<a href="http://energy.gov/eere/femp/guiding-principles-federal-leadership-high-performance-and-sustainable-buildings">http://energy.gov/eere/femp/guiding-principles-federal-leadership-high-performance-and-sustainable-buildings</a>).',
      labelValue: 'Guiding principles --  Develop a successful project incorporating the Guiding Principles for Federal Leadership in High Performance and Sustainable Buildings:  1) Employ integrated design principles;  2) Optimize energy performance;  3) Protect and conserve water;  4) Enhance indoor environmental quality;  5) Reduce environmental impact of materials. (http://energy.gov/eere/femp/guiding-principles-federal-leadership-high-performance-and-sustainable-buildings).',
      selected: false
    },
    {value: 9, label: 'Improve indoor air quality.', labelValue: 'Improve indoor air quality.', selected: false},
    {value: 10, label: 'Improve occupant comfort.', labelValue: 'Improve occupant comfort.', selected: false},

    {
      value: 11,
      label: 'Known hazardous materials. Include dealing with hazardous materials.',
      labelValue: 'Known hazardous materials. Include dealing with hazardous materials.',
      selected: false
    },
    {
      value: 12,
      label: 'Develop a project with the potential for replication at other agency facilities.',
      labelValue: 'Develop a project with the potential for replication at other agency facilities.',
      selected: false
    },
    {
      value: 13,
      label: 'M&V - Implement cost-effective, appropriate project measurement and verification (M&V) that meets our needs and approval.',
      labelValue: 'M&V - Implement cost-effective, appropriate project measurement and verification (M&V) that meets our needs and approval.',
      selected: false
    },
    {
      value: 14,
      label: 'Achieve a successful project that effectively balances technical/financial/other risks between ESCO and agency.',
      labelValue: 'Achieve a successful project that effectively balances technical/financial/other risks between ESCO and agency.',
      selected: false
    },
    {value: 15, label: 'Financing - Minimize financing costs.', labelValue: 'Financing -- Minimize financing costs.', selected: false},
    {
      value: 16,
      label: 'Maintenance -- Include reliable, cost-effective preventative maintenance of ECM assets.',
      labelValue: 'Maintenance -- Include reliable, cost-effective preventative maintenance of ECM assets.',
      selected: false
    },
    {
      value: 17,
      label: 'Compliance and Permitting -- Develop a successful project that effectively overcomes challenges in complying with applicable regulations and obtaining required permits.',
      labelValue: 'Compliance and Permitting -- Develop a successful project that effectively overcomes challenges in complying with applicable regulations and obtaining required permits.',
      selected: false
    },
    {
      value: 18,
      label: 'Financial incentives -- Reduce project costs by identifying and applying all available rebates; local, state, or regional financial incentives; renewable energy certificates (RECs); and other investment or tax credits.',
      labelValue: 'Financial incentives -- Reduce project costs by identifying and applying all available rebates; local, state, or regional financial incentives; renewable energy certificates (RECs); and other investment or tax credits.',
      selected: false
    },
    {value: 19, label: 'Implement a Smart Laboratory Program -- Reduce ventilation during occupied and unoccupied time periods based on a ventilation risk assessment, employ high ventilation effectiveness design, minimize fan energy, use smart building controls',
    labelValue: 'Implement a Smart Laboratory Program -- Reduce ventilation during occupied and unoccupied time periods based on a ventilation risk assessment, employ high ventilation effectiveness design, minimize fan energy, use smart building controls',
    selected: false},

    {value: 20, label: 'Implement 50001-Ready Program', labelValue: 'Implement 50001-Ready Program', selected: false},

    {value: 21, label: 'Other (write-in)', labelValue: 'Other (write-in)', selected: false}
  ];

