export const options = [
    {value: 0, label: 'Office Space'},
    {value: 1, label: 'Foreign Sites'},
    {value: 2, label: 'Hospitals/medical/healthcare facilities'},
    {value: 3, label: 'Prisons'},
    {value: 4, label: 'Laboratories/clean rooms'},
    {value: 5, label: 'Dining facilities'},
    {value: 6, label: 'Data centers'},
    {value: 7, label: 'Process facilities'},
    {value: 8, label: 'Aircraft hangars'},
    {value: 9, label: 'Motor pools'},
    {value: 10, label: 'Central plants'},
    {value: 11, label: 'Runways'},
    {value: 12, label: 'Parking facilities'},
    {value: 13, label: 'Barracks/dormitories'},
    {value: 14, label: 'Other residential'},
    {value: 15, label: 'Small, geographically dispersed sites'},
    {value: 16, label: 'Other'}
  ];
