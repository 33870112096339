<div class="jumbotron" >
  <div class="container" >
    <h2>3 Statement of Objectives</h2>

    <p><strong>Section 3</strong> is the agency’s statement of objectives for the procurement described by the NOO.
      Section 3.1 is a general statement of objectives, and Section 3.2 calls for statement of 2 - 3 of the agency's highest-priority objectives.
      </p>

    <p><a class="btn btn-primary btn-lg" (click)="openMODAL('statementOfObjectivesModal')">Learn
      more about stating objectives&raquo;</a>&nbsp;&nbsp;<a class="btn btn-primary btn-lg" (click)="openMODAL('moreSoModal')">Learn
      more about objectives and matching evaluation criteria&raquo;</a>
    </p>
  </div>
</div>
<div class="container">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="panel-title">Select options describing project objectives</h3>
    </div>
    <div class="panel-body">

      <p><strong>3.1 General statement of objectives </strong></p>

      <div class="form-group">
        <div class="row">
          <label for="useBoilerplateStatementOfObjectives" class="col-sm-2 control-label">Use Default Text?</label>

          <div class="col-sm-10" id="useBoilerplateStatementOfObjectives">
            <input  id="boilerplateStatementOfObjectives"  type="checkbox" (change)="updateSelected($event, 'boilerplateStatementOfObjectives', 'useBoilerplateStatementOfObjectives');
            clearRelated($event, 'statementOfObjectives');"   [(ngModel)]="nooData.useBoilerplateStatementOfObjectives" />
            The project will accomplish significant reductions at&nbsp;<mark>{{nooData.facility_name || "Facility Name"}}</mark>&nbsp;in energy
            and water use, energy, water and O&amp;M costs, and enhance energy and water resilience by implementing
            energy and water conservation measures (ECMs) as listed in attachment J-3 of the Fourth Generation DOE ESPC IDIQ contract. The
            ESCO shall explore and recommend all ECMs that may be economically viable and will contribute to the
            overall project goals and objectives.The ESCO should reduce project costs and increase project benefits
            by leveraging available direct funding; identifying and supporting agency application for available utility
            rebates; local, state, and regional financial incentives; renewable energy certificates (RECs); and other
            investment or tax credits and supporting agency application for AFFECT and other available federal funding.
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="!nooData.useBoilerplateStatementOfObjectives">
        <div class="row">
          <label for="statementOfObjectives" class="col-sm-2 control-label">Write in other general statement of
            objectives</label>

          <div class="col-sm-10">
      <textarea class="form-control"   id="statementOfObjectives" name="statementOfObjectives"
                [(ngModel)]="nooData.boilerplateStatementOfObjectives" rows="5"></textarea>
          </div>
        </div>
      </div>
      <hr>

      <p><strong>3.2 Agency project objectives/goals/priorities </strong></p>

        <p><a style="cursor:pointer" (click)="openMODAL('moreSoModal')">Learn
        more about objectives and matching evaluation criteria.</a></p>
        <p style="font-weight: normal; font-size: small">To select more than one option in the list:  for Windows or Linux CTRL+click each item; for Mac Cmd+Click.</p>

        <p><strong>3.2.1 General Project Objectives&nbsp;&nbsp;</strong><button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#gpo">&nbsp;&raquo;&nbsp;</button></p>

        <span class="collapse" id="gpo">
          <p><strong>Entering objectives and related evaluation criteria</strong></p>
          <p>Check the box for a General Project Objective to:
          <ol>
          <li>add the objective to the NOO, and </li>
          <li>add a related suggested evaluation criterion to the evaluation forms.</li>
        </ol>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div *ngFor=" let generalObjective of generalObjectives">
                <div class="checkbox">
                  <label>
                    <input type="checkbox"   (change)="onCheckbox($event, generalObjective)" [(ngModel)] = "generalObjective.selected" >&nbsp;<span [innerHTML]="generalObjective.label"></span><br>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <div class="col-sm-11">
              <textarea class="form-control" rows="4" id="other_goal_textarea" name="other_goal_textarea"
                    [(ngModel)]="nooData.other_goal_textarea" *ngIf="nooData.generalObjectives.indexOf(generalObjectives[21]) > -1"></textarea>
            </div>
          </div>
        </div>

        </span>

        <p><strong>3.2.2 Specific Goals - Energy Use and Cost Reduction&nbsp;&nbsp;</strong><button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#sgeucr">&nbsp;&raquo;&nbsp;</button></p>

        <span class="collapse" id="sgeucr">

          <p><strong>3.2.2.1 Specific ECMs for comprehensive/deep savings</strong></p>


        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="comprehensive_savings_deep_energy" type="checkbox" name="comprehensive_savings_deep_energy" trueFalseValue falseValue=""
                         [(ngModel)]="nooData.has_comprehensive_savings_deep_energy"  trueValue="Achieve comprehensive project and deep energy savings using:" (change)="updateExpanded($event, ['energyEcms'])"/>&nbsp;Achieve
                  comprehensive project and deep energy savings using:
                </label>
              </div>

              <div class="row" *ngIf="nooData.has_comprehensive_savings_deep_energy">
                <div class="col-sm-6">
                  <label for="energyEcms">Select ECMs:</label>

                  <select class="form-control" size="10" id="energyEcms" multiple ng-multiple="true" [(ngModel)]="nooData.energyEcms">
                    <option *ngFor= "let energyEcm of energyEcms " [ngValue]="energyEcm" > {{energyEcm.label}} </option>
                    </select>
                </div>
              </div>

               <div class="row" *ngIf="(nooData.has_comprehensive_savings_deep_energy && nooData.energyEcms.indexOf(energyEcms[11]) > -1) || nooData.hasOwnProperty('idiqsDeepEnergy')">
                <div class="col-sm-12">
                  <label for="idiqsDeepEnergy">Select IDIQ Technology Categories</label>
                  <select class="form-control" size="10" id="idiqsDeepEnergy" multiple ng-multiple="true" style="word-wrap: break-word;"
                  [(ngModel)]="nooData.idiqsDeepEnergy">
                    <option *ngFor= "let idiqEcm of idiqEcms " [ngValue]="idiqEcm" > {{idiqEcm.label}} </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="comprehensive_savings_deep_water" type="checkbox" (change)="updateExpanded($event, ['waterEcms'])"
                         [(ngModel)]="nooData.has_comprehensive_savings_deep_water" trueFalseValue trueValue="Achieve comprehensive project and deep water savings using:" falseValue=" "/>&nbsp;Achieve
                  comprehensive project and deep water savings using:
                </label>
              </div>
              <div class="row" *ngIf="nooData.has_comprehensive_savings_deep_water">
                <div class="col-sm-6">
                  <label for="waterEcms">Select ECMs</label>
                  <select class="form-control" size="10" id="waterEcms" multiple ng-multiple="true" [(ngModel)]="nooData.waterEcms">
                    <option *ngFor= "let waterEcm of waterEcms " [ngValue]="waterEcm" > {{waterEcm.label}} </option>
                  </select>
                   </div>
                <div class="col-sm-6" *ngIf="nooData.waterEcms.indexOf(waterEcms[15]) > -1">
                  <label for="waterEcmsOtherText">Other:</label>
                          <textarea rows="4" class="form-control" id="waterEcmsOtherText" name="waterEcmsOtherText"
                                    [(ngModel)]="nooData.water_ecms_other_textarea"></textarea>
                </div>
              </div>

              <div class="row" *ngIf="(nooData.has_comprehensive_savings_deep_water && nooData.waterEcms.indexOf(waterEcms[16]) > -1) || nooData.hasOwnProperty('idiqsDeepWater')">
                <div class="col-sm-12">
                  <label for="idiqsDeepWater">Select IDIQ Technology Categories</label>
                  <select class="form-control" size="10" id="idiqsDeepWater" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.idiqsDeepWater">
                    <option *ngFor= "let idiqEcm of idiqEcms " [ngValue]="idiqEcm" > {{idiqEcm.label}} </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>


        <p><strong>3.2.2.2 Energy Use and Cost Reduction Goals</strong></p>
          <p>User choice of an objective below enters a matching suggested evaluation criteria into the evaluation forms.</p>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="use_has_specific_level" type="checkbox" [(ngModel)]="nooData.use_has_specific_level" (change)="updateSelected($event, 'has_specific_level', 'use_has_specific_level')"/>Achieve specific level of energy/water/cost savings
                  <input type="text" value="specify" id="specific_savings_value" [(ngModel)]="nooData.specific_savings_value" (blur)="updateSelected($event, 'has_specific_level', 'use_has_specific_level')">
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label> <input id="use_decrease_intensity" type="checkbox" [(ngModel)]="nooData.use_has_decrease_intensity" (change)="updateSelected($event, 'has_decrease_intensity', 'use_has_decrease_intensity')"/>Decrease
                  energy intensity by
                  <input type="text" value="specify" id="intensity_decrease_value" [(ngModel)]="nooData.intensity_decrease_value" (blur)="updateSelected($event, 'has_decrease_intensity', 'use_has_decrease_intensity')">
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label> <input id="use_decrease_water_consumption" type="checkbox" [(ngModel)]="nooData.use_has_decrease_water_consumption" (change)="updateSelected($event, 'has_decrease_water_consumption', 'use_has_decrease_water_consumption')"/>Decrease water consumption by
                  <input type="text" value="specify" id="water_consumption_decrease_value" [(ngModel)]="nooData.water_consumption_decrease_value" (blur)="updateSelected($event, 'has_decrease_water_consumption', 'use_has_decrease_water_consumption')">
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label> <input id="decrease_maintenance_costs" type="checkbox"
                               [(ngModel)]="nooData.has_decrease_maintenance_costs" trueFalseValue trueValue="Decrease maintenance costs" falseValue="undefined"/>Decrease
                  maintenance costs </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="reduce_utility_costs" type="checkbox" trueFalseValue trueValue="Reduce utility costs" falseValue="undefined"
                         [(ngModel)]="nooData.has_reduce_utility_costs"/>Reduce utility costs </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="reduce_ozone" type="checkbox"  trueFalseValue trueValue="Reduce use of ozone-depleting compounds" falseValue="undefined"
                   [(ngModel)]="nooData.has_reduce_ozone" />Reduce use of ozone-depleting compounds </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="reduce_greenhouse" type="checkbox" trueFalseValue trueValue="Enhance energy and water resilience" falseValue="undefined"
                  [(ngModel)]="nooData.has_reduce_greenhouse"/>Enhance energy and water resilience </label>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="form-group">-->
<!--          <div class="row">-->
<!--            <div class="col-sm-12">-->
<!--              <div class="checkbox">-->
<!--                <label>-->
<!--                  <input id="fossil_fuel" type="checkbox" trueFalseValue trueValue="Reduce the use of fossil-fuel-generated energy" falseValue="undefined" [(ngModel)]="nooData.has_fossil_fuel"/>Reduce-->
<!--                  the use of fossil-fuel-generated energy </label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="other_reduction_goal" type="checkbox" [(ngModel)]="nooData.has_other_reduction_goal_writein"/>Other
                  (write-in) </label>
              </div>
              <div class="col-sm-12">
          <textarea rows="4" class="form-control" id="other_reduction_goal_textarea" name="other_reduction_goal_textarea"
          [(ngModel)]="nooData.other_reduction_goal_textarea" *ngIf="nooData.has_other_reduction_goal_writein"></textarea>
              </div>
            </div>
          </div>
        </div>
        </span>

<!--        <p><strong>3.2.3 Long term reduction of carbon and other greenhouse gas emissions&nbsp;&nbsp;</strong><button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#carbon">&nbsp;&raquo;&nbsp;</button></p>-->
<!--        <span id="carbon" class="collapse">-->
<!--          <div class="form-group">-->
<!--            <div class="row">-->
<!--              <div class="col-sm-12">-->
<!--                <div class="checkbox">-->
<!--                  <label>-->
<!--                    <input id="gas_reduction"    trueFalseValue trueValue ="Achieve specific level of greenhouse gas reduction" falseValue="undefined"-->
<!--                    type="checkbox" [(ngModel)]="nooData.has_gas_reduction"/>Achieve specific level of greenhouse gas reduction</label>-->
<!--                </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            <div class="row">-->
<!--              <div class="col-sm-12">-->
<!--              <div class="checkbox">-->
<!--                <label>-->
<!--                  <input id="emissions_solutions"    trueFalseValue trueValue ="Inclusion of low and no emissions solutions" falseValue="undefined"-->
<!--                  type="checkbox" [(ngModel)]="nooData.has_emissions_solutions"/>Inclusion of low and no emissions solutions</label>-->
<!--              </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--            <div class="col-sm-12">-->
<!--              <div class="checkbox">-->
<!--                <label>-->
<!--                  <input id="emissions_evaluation"    trueFalseValue trueValue ="Evaluation of low and no emissions benefits that may outweigh less attractive economics when compared to standard ECMs" falseValue="undefined"-->
<!--                  type="checkbox" [(ngModel)]="nooData.has_emissions_evaluation"/>Evaluation of low and no emissions benefits that may outweigh less attractive economics when compared to standard ECMs</label>-->
<!--              </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--              <div class="col-sm-12">-->
<!--                <div class="checkbox">-->
<!--                  <label>-->
<!--                    <input id="ECMs_evaluation"    trueFalseValue trueValue ="Evaluation of ECMs that may perpetuate the use of fossil fuels or otherwise contribute to carbon and other GHG emissions and explore alternatives where appropriate" falseValue="undefined"-->
<!--                    type="checkbox" [(ngModel)]="nooData.has_ECMs_evaluation"/>Evaluation of ECMs that may perpetuate the use of fossil fuels or otherwise contribute to carbon and other GHG emissions and explore alternatives where appropriate</label>-->
<!--                </div>-->
<!--                </div>-->
<!--              </div>-->
<!--          </div>-->
<!--        </span>-->
        <p><strong>3.2.3 Energy Security and Resilience Objectives&nbsp;&nbsp;</strong><button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#esro">&nbsp;&raquo;&nbsp;</button></p>
        <span id="esro" class="collapse">
          <p><strong>Objectives in Developing Project Scope</strong></p>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="broad_study"    trueFalseValue trueValue ="Provide a broad study of all technology approaches that address the site's energy security, resiliency and cybersecurity needs" falseValue="undefined"
                  type="checkbox" [(ngModel)]="nooData.has_broad_study"/>Provide a broad study of all technology approaches that address the site's energy security, resiliency and cybersecurity needs with special focus on the following technologies</label>
              </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="broad_study_tech" trueFalseValue trueValue="Provide a broad study of all technology approaches that address the site's energy security, resiliency and cybersecurity needs with special focus on the following technologies" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_broad_study_tech" (change)="updateExpanded($event, ['esrosTech'])"/>Provide a broad study of all technology approaches that address the site's energy security, resiliency and cybersecurity needs with special focus on the following technologies</label>
              </div>
              <div class="row" *ngIf="nooData.has_broad_study_tech">
                <div class="col-sm-12">
                  <label for="esrosTech">Select Technologies</label>
                  <select class="form-control" size="9" id="esrosTech" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.esrosTech">
                    <option *ngFor= "let esro of esros " [ngValue]="esro" > {{esro.label}} </option>
                  </select>
                </div>
              </div>
              </div>
            </div>
           <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="island_mode" trueFalseValue trueValue="Need for island mode to serve critical loads" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_island_mode"/>Need for island mode to serve critical loads</label>
              </div>
              </div>
            </div>
           <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="black_start" trueFalseValue trueValue="Ability to restore site energy generation assets without power from external grid (black start)" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_black_start"/>Ability to restore site energy generation assets without power from external grid (black start)</label>
              </div>
              </div>
            </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="use_has_outage_solutions" type="checkbox" [(ngModel)]="nooData.use_has_outage_solutions" (change)="updateSelected($event, 'has_outage_solutions', 'use_has_outage_solutions')"/>Solutions to address outage duration of
                  <input type="text" value="specify" id="outage_solution_value" [(ngModel)]="nooData.outage_solution_value" (blur)="updateSelected($event, 'has_outage_solutions', 'use_has_outage_solutions')"> days
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="use_has_availability_level" type="checkbox" [(ngModel)]="nooData.use_has_availability_level" (change)="updateSelected($event, 'has_availability_level', 'use_has_availability_level')"/>Desired system availability:
                  <input type="text" value="specify" id="availability_level_value" [(ngModel)]="nooData.availability_level_value" (blur)="updateSelected($event, 'has_availability_level', 'use_has_availability_level')"><br><small><i>Note: If known, state desired system availability (ex: "99.9%" availability = 8.7 hours downtime per year</i></small>
                </label>
              </div>
            </div>
          </div>
           <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="op_main" trueFalseValue trueValue="ESCO operation and maintenance of energy security/resiliency asset" falsValue="undefined" type="checkbox" [(ngModel)]="nooData.has_op_main"/>ESCO operation and maintenance of energy security/resiliency asset</label>
              </div>
              </div>
            </div>
           <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="redundant_backup" trueFalseValue trueValue="Redundant/backup systems" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_redundant_backup"/>Redundant/backup systems</label>
              </div>
              </div>
            </div>
           <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="scalable_systems" trueFalseValue trueValue="Scalable systems" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_scalable_systems"/>Scalable systems</label>
              </div>
              </div>
            </div>
           <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="duel_fuel" trueFalseValue trueValue="Duel fuel capability" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_duel_fuel"/>Dual fuel capability</label>
              </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="storage_systems" trueFalseValue trueValue="Incorporate energy storage systems" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_storage_systems"/>Incorporate energy storage systems</label>
              </div>
              </div>
            </div>

           <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="consider_esa" trueFalseValue trueValue="Consider utilization of Energy Sales Agreement (ESA) for addressing ownership and operation of distributed energy generation asset (Note: ESCO must address this objective in their Management Approach response)" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_consider_esa"/>Consider utilization of Energy Sales Agreement (ESA) for addressing ownership and operation of distributed energy generation asset (Note: ESCO must address this objective in their Management Approach response) <br><small><i>Note: If selected, Agency should also add to write-in areas within Section 5 Required Submittals and Section 6 Evaluation Criteria</i></small></label>
              </div>
              </div>
            </div>
           <div class="row">
              <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="penalty" trueFalseValue trueValue="Penalty structure for site energy outage (Note: ESCO must address in response on Management Approach)" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_penalty"/>Penalty structure for site energy outage  (ESCO must address in response on Management Approach) <br><small><i>Note: If selected, Agency should also add to write-in areas within Section 5 Required Submittals and Section 6 Evaluation Criteria</i></small></label>
              </div>
              </div>
            </div>




            <div class="row">
              <div class="col-sm-9">
                <label for="evaluationResSpecifics">Select Evaluation Criteria:</label>
                <select class="form-control" size="4" id="evaluationResSpecifics" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.evaluationRes">
                  <option *ngFor= "let evaluationRe of evaluationRes " [ngValue]="evaluationRe" > {{evaluationRe.label}} </option>              </select>
            </div>
          </div>
          </div>
        </span>

        <p><strong>3.2.4 Objectives for Specific ECMs&nbsp;&nbsp;</strong><button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#ose">&nbsp;&raquo;&nbsp;</button></p>
        <span id="ose" class="collapse">
          <p>Users can define individual objectives for individual ECMs in Section 3.2.4</p>
          <p>Check a box for an objective to:</p>
          <ol>
            <li>open a pick-list of ECMs to add a particular ECM to the objective, and </li>
            <li>open a list of evaluation criteria and choose to enter into the evaluation forms.</li>
            <li>Select IDIQ Technology Categories

            </li>

          </ol>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="thoroughly_explore" trueFalseValue trueValue="Thoroughly explore opportunities for including the following ECMs"
                  falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_thoroughly_explore"
                  (change)="updateExpanded($event, ['idiqsExplores'])"/>Thoroughly explore opportunities for including the following ECMs</label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="nooData.has_thoroughly_explore ">
            <div class="col-sm-12">
              <label for="idiqsExplores">Select IDIQ Technology Categories</label>
              <select class="form-control" size="10" id="idiqsExplores" multiple ng-multiple="true" style="word-wrap: break-word;"
              [(ngModel)]="nooData.idiqsExplores">
               <option *ngFor= "let idiqEcm of idiqEcms " [ngValue]="idiqEcm" > {{idiqEcm.label}} </option>
              </select>
            </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <div class="checkbox">
                    <label>
                      <input id="Study_Based_Commissioning" type="checkbox" trueFalseValue
                      trueValue="Provide a study of the use of re-/retro-commissioning (RCx) coupled with a monitoring-based
                      commissioning (MBCx) tool to identify ECMs during the development, implementation or performance phase
                      of the project. The objective of this activity would be to capture these ECMs’ savings, including those
                      that may vary from year to year, possibly allowing the inclusion of additional measures that were not originally deemed
                      financeable through the project (reference study)" falseValue="undefined" [(ngModel)]="nooData.has_study_based_commissioning"/>
                      Provide a study of the use of re-/retro-commissioning (RCx) coupled with a monitoring-based commissioning (MBCx) tool to identify ECMs
                      during the development, implementation or performance phase of the project. The objective of this activity would be to capture these ECMs’
                      savings, including those that may vary from year to year, possibly allowing the inclusion of additional measures that were not originally
                      deemed financeable through the project (reference study)</label>
                  </div>
                </div>
              </div>
            </div>
<!--        <div class="form-group">-->
<!--          <div class="row">-->
<!--            <div class="col-sm-12">-->
<!--              <div class="checkbox">-->
<!--                <label>-->
<!--                  <input id="include_diversity" trueFalseValue trueValue="Include a diversity of renewable energy ECMs in the project" falseValue="undefined" type="checkbox" [(ngModel)]="nooData.has_include_diversity" (change)="updateExpanded($event, ['eeds', 'diversitysEvalCriteria'])"/>Include-->
<!--                  a diversity of renewable energy ECMs in the project </label>-->
<!--              </div>-->
<!--              <div class="row" *ngIf="nooData.has_include_diversity">-->
<!--                <div class="col-sm-3">-->
<!--                  <label for="eeds">Select ECMs:</label>-->
<!--                  <select class="form-control" size="10" id="eeds" multiple ng-multiple="true" [(ngModel)]="nooData.eeds">-->
<!--                    <option *ngFor= "let energyEcm of energyEcms " [ngValue]="energyEcm" > {{energyEcm.label}} </option>-->
<!--                    </select>-->
<!--                </div>-->
<!--                <div class="col-sm-9">-->
<!--                  <label for="evalCriteriaDiversitys">Select Evaluation Criteria:</label>-->
<!--                  <select class="form-control" size="10" id="evalCriteriaDiversitys" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.diversitysEvalCriteria">-->
<!--                    <option *ngFor= "let evaluationEcmSpecific of evaluationEcmSpecifics " [ngValue]="evaluationEcmSpecific" > {{evaluationEcmSpecific.label}} </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row" *ngIf="(nooData.has_include_diversity && nooData.eeds.indexOf(energyEcms[11]) > -1) || nooData.hasOwnProperty('idiqsDiversitys')">-->
<!--                <div class="col-sm-12">-->
<!--                  <label for="idiqsDiversitys">Select IDIQ Technology Categories</label>-->
<!--                  <select class="form-control" size="10" id="idiqsDiversitys" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.idiqsDiversitys">-->
<!--                    <option *ngFor= "let idiqEcm of idiqEcms " [ngValue]="idiqEcm" > {{idiqEcm.label}} </option>-->
<!--                    </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <div class="row">-->
<!--            <div class="col-sm-12">-->
<!--              <div class="checkbox">-->
<!--                <label>-->
<!--                  <input id="develop_renewable" type="checkbox" trueFalseValue trueValue="Develop renewable energy resources" falseValue="undefined" [(ngModel)]="nooData.has_develop_renewable" (change)="updateExpanded($event, ['eevs', 'evalCriteriaDevelops'])"/>Develop-->
<!--                  renewable energy resources </label>-->
<!--              </div>-->
<!--              <div class="row" *ngIf="nooData.has_develop_renewable">-->
<!--                <div class="col-sm-3">-->
<!--                  <label for="eevs">Select ECMs:</label>-->
<!--                  <select class="form-control" size="10" id="eevs" multiple ng-multiple="true" [(ngModel)]="nooData.eevs">-->
<!--                    <option *ngFor= "let energyEcm of energyEcms " [ngValue]="energyEcm" > {{energyEcm.label}} </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--                <div class="col-sm-9">-->
<!--                  <label for="evalCriteriaDevelops">Select Evaluation Criteria:</label>-->
<!--                  <select class="form-control" size="10" id="evalCriteriaDevelops" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.evalCriteriaDevelops">-->
<!--                    <option *ngFor= "let evaluationEcmSpecific of evaluationEcmSpecifics " [ngValue]="evaluationEcmSpecific" > {{evaluationEcmSpecific.label}} </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row" *ngIf="(nooData.has_develop_renewable && nooData.eevs.indexOf(energyEcms[11]) > -1) || nooData.hasOwnProperty('idiqsRenewables')">-->
<!--                <div class="col-sm-12">-->
<!--                  <label for="idiqsRenewables">Select IDIQ Technology Categories</label>-->
<!--                  <select class="form-control" size="10" id="idiqsRenewables" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.idiqsRenewables">-->
<!--                    <option *ngFor= "let idiqEcm of idiqEcms " [ngValue]="idiqEcm" > {{idiqEcm.label}} </option>-->
<!--                </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <div class="row">-->
<!--            <div class="col-sm-12">-->
<!--              <div class="checkbox">-->
<!--                <label>-->
<!--                  <input id="utilize_renewable" type="checkbox" trueFalseValue falseValue="undefined" trueValue="Utilize renewable energy" [(ngModel)]="nooData.has_utilize_renewable" (change)="updateExpanded($event, ['eeurs', 'evalCriteriaUtilizeRenewables'])"/>Utilize-->
<!--                  renewable energy </label>-->
<!--              </div>-->
<!--              <div class="row" *ngIf="nooData.has_utilize_renewable">-->
<!--                <div class="col-sm-3">-->
<!--                  <label for="eeurs">Select ECMs:</label>-->
<!--                  <select class="form-control" size="10" id="eeurs" multiple ng-multiple="true" [(ngModel)]="nooData.eeurs">-->
<!--                    <option *ngFor= "let energyEcm of energyEcms " [ngValue]="energyEcm" > {{energyEcm.label}} </option>-->
<!--                </select>-->
<!--                </div>-->
<!--                <div class="col-sm-9">-->
<!--                  <label for="evalCriteriaUtilizeRenewables">Select Evaluation Criteria:</label>-->
<!--                  <select class="form-control" size="10" id="evalCriteriaUtilizeRenewables" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.evalCriteriaUtilizeRenewables">-->
<!--                    <option *ngFor= "let evaluationEcmSpecific of evaluationEcmSpecifics " [ngValue]="evaluationEcmSpecific" > {{evaluationEcmSpecific.label}} </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row" *ngIf="(nooData.has_utilize_renewable && nooData.eeurs.indexOf(energyEcms[11]) > -1) || nooData.hasOwnProperty('idiqsRenewablesUtilize')">-->
<!--                <div class="col-sm-12">-->
<!--                  <label for="idiqsRenewablesUtilize">Select IDIQ Technology Categories</label>-->
<!--                  <select class="form-control" size="10" id="idiqsRenewablesUtilize" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.idiqsRenewablesUtilize">-->
<!--                    <option *ngFor= "let idiqEcm of idiqEcms " [ngValue]="idiqEcm" > {{idiqEcm.label}} </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="fossil_fuel" type="checkbox" trueFalseValue trueValue="Convert fossil-fuel consuming equipment to electric for potential cost savings and compliance with Section 433 of the Energy Independence and Security Act of 2007 (EISA)." falseValue="undefined" [(ngModel)]="nooData.has_fossil_fuel"/>Convert fossil-fuel consuming equipment to electric for potential cost savings and compliance with Section 433 of the Energy Independence and Security Act of 2007 (EISA).</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="both_traditional_and_renewable" type="checkbox" trueFalseValue trueValue="Use both traditional and distributed technologies to achieve high-performance green buildings" falseValue="undefined"
                         [(ngModel)]="nooData.has_both_traditional_and_renewable" (change)="updateExpanded($event, ['eets', 'evalCriteriaTraditionals'])"/>Use
                  both traditional and distributed technologies to achieve high-performance green buildings </label>
              </div>
              <div class="row" *ngIf="nooData.has_both_traditional_and_renewable">
                <div class="col-sm-3">
                  <label for="eets">Select ECMs:</label>
                  <select class="form-control" size="10" id="eets" multiple ng-multiple="true" [(ngModel)]="nooData.eets">
                    <option *ngFor= "let energyEcm of energyEcms " [ngValue]="energyEcm" > {{energyEcm.label}} </option>
                    </select>
                </div>
                <div class="col-sm-9">
                  <label for="evalCriteriaTraditionals">Select Evaluation Criteria:</label>
                  <select class="form-control" size="10" id="evalCriteriaTraditionals" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.evalCriteriaTraditionals">
                    <option *ngFor= "let evaluationEcmSpecific of evaluationEcmSpecifics " [ngValue]="evaluationEcmSpecific" > {{evaluationEcmSpecific.label}} </option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="(nooData.has_both_traditional_and_renewable && nooData.eets.indexOf(energyEcms[11]) > -1) || nooData.hasOwnProperty('idiqsTraditionalsRenewables')">
                <div class="col-sm-12">
                  <label for="idiqsTraditionalsRenewables">Select IDIQ Technology Categories</label>
                  <select class="form-control" size="10" id="idiqsTraditionalsRenewables" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.idiqsTraditionalsRenewables">
                    <option *ngFor= "let idiqEcm of idiqEcms " [ngValue]="idiqEcm" > {{idiqEcm.label}} </option>
                  </select>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox" >
                <label >
                    <input id="use_has_rebate_incentive" type="checkbox"  [(ngModel)]="nooData.use_has_rebate_incentive" (change)="updateSelected($event, 'has_rebate_incentive', 'use_has_rebate_incentive')"/>Leverage
                    a high-value ESPC using energy rebates and incentives available in
                    <input type="text" value="specify" id="applicable_regions" [(ngModel)]="nooData.applicable_regions" (blur)="updateSelected($event, 'has_rebate_incentive', 'use_has_rebate_incentive')"> and
                    for specific ECMs </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="energy_star" type="checkbox" [(ngModel)]="nooData.has_energy_star" trueFalseValue trueValue="Achieve high energy efficiency using Energy Star certified and FEMP-designated products" falseValue="undefined"/>Achieve
                  high energy efficiency using Energy Star certified and FEMP-designated products </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="emcs_metering" type="checkbox" trueFalseValue trueValue="EMCS and metering -- Apply fault detection and alarming algorithms to all trended interval data from both existing and future metering and automation system points" falseValue="undefined" [(ngModel)]="nooData.has_emcs_metering"/>EMCS
                  and metering -- Apply fault detection and alarming algorithms to all trended interval data from both
                  existing and future metering and automation system points </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="Monitoring_Based_Commissioning" type="checkbox" trueFalseValue trueValue="Consider Monitoring-Based Commissioning" falseValue="undefined" [(ngModel)]="nooData.has_monitoring_based_commissioning"/>Consider Monitoring-Based Commissioning</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="Consider_Data_Center_Improvements" type="checkbox" trueFalseValue trueValue="Consider Data Center Improvements" falseValue="undefined" [(ngModel)]="nooData.has_data_center_improvements"/> Consider Data Center Improvements</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="Implement_50001_Ready_Program" type="checkbox" trueFalseValue trueValue="Implement 50001-Ready Program" falseValue="undefined" [(ngModel)]="nooData.has_50001_ready_program"/> Implement 50001-Ready Program</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="Implement_Smart_Laboratories_Program" type="checkbox" trueFalseValue trueValue="Implement a Smart Laboratories Program" falseValue="undefined" [(ngModel)]="nooData.has_smart_laboratories_program"/> Implement a Smart Laboratories Program</label>
              </div>
            </div>
          </div>
        </div>



        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label> <input id="other_specific_ecms" type="checkbox"
                               [(ngModel)]="nooData.has_other_specific_ecms_writein"/>Other (write-in) </label>
              </div>
              <div class="col-sm-12">
          <textarea class="form-control" id="other_specific_ecms_textarea" name="other_specific_ecms_textarea"
                    [(ngModel)]="nooData.other_specific_ecms_textarea" *ngIf="nooData.has_other_specific_ecms_writein"></textarea>
              </div>
            </div>
          </div>
        </div>
        </span>

        <p><strong>3.2.5 CHP Project Objectives&nbsp;&nbsp;</strong><button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#cpo">&nbsp;&raquo;&nbsp;</button></p>
        <span class="collapse" id="cpo">

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="combined_heat_and_power" type="checkbox" [(ngModel)]="nooData.has_combined_heat_power" (change)="updateExpanded($event, ['evalCriteriaCHP'])" trueFalseValue trueValue="Implement combined heating and power system" falseValue="undefined"/>Implement combined heating and power system </label>
              </div>

          </div>
        </div></div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="use_has_chp_plant_installation" type="checkbox" (change)="updateExpanded($event, ['evalCriteriaCHPInstallation']); updateSelected($event, 'has_chp_plant_installation', 'use_has_chp_plant_installation')"
                         [(ngModel)]="nooData.has_chp_plant_installation"/>Installation of a CHP plant that produces
                  <input type="text" value="specify" id="chp_plant_electricity_value"
                         [(ngModel)]="nooData.chp_plant_electricity_value" (blur)="updateSelected($event, 'has_chp_plant_installation', 'use_has_chp_plant_installation')"> MW of electricity </label>
              </div>

            </div>
          </div></div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="year_round_steam" type="checkbox" (change)="updateExpanded($event, ['evalCriteriaCHPYearRoundSteam'])" [(ngModel)]="nooData.has_year_round_steam"  trueFalseValue trueValue="Providing steam requirements year around at the installation" falseValue="undefined"/>Providing
                  steam requirements year around at the installation </label>
              </div>

          </div>
        </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="utilize_waste_heat" type="checkbox" (change)="updateExpanded($event, ['evalCriteriaCHPWasteHeat'])" [(ngModel)]="nooData.has_utilize_waste_heat" trueFalseValue trueValue="Utilize waste heat for process/facility heating and cooling" falseValue="undefined"/>Utilize
                  waste heat for process/facility heating and cooling </label>
              </div>

          </div>
        </div></div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="operate_thru_grid_outage" type="checkbox" (change)="updateExpanded($event, ['evalCriteriaCHPGridOutage'])" trueFalseValue trueValue="Installation of a CHP system that can continue to operate through utility grid outages" falseValue="undefined"
                         [(ngModel)]="nooData.has_operate_thru_grid_outage"/>Installation of a CHP system that can continue to operate through utility grid outages </label>
              </div>

            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="utilize_renewables" (change)="updateExpanded($event, ['evalCriteriaCHPRenewables'])" type="checkbox" [(ngModel)]="nooData.has_utilize_bios" trueFalseValue trueValue="Installation of a CHP system that utilizes renewable fuels (biomass/biogas, landfill gas)" falseValue="undefined"/>Installation
                  of a CHP system that utilizes renewable fuels (biomass/biogas, landfill gas) </label>
              </div>

            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="reduce_use" type="checkbox" (change)="updateExpanded($event, ['evalCriteriaCHPReduceUse'])" [(ngModel)]="nooData.has_reduce_use_boilers" trueFalseValue trueValue="Reducing the use of the current gas/oil fired boilers at the installation" falseValue="undefined"/>Reducing
                  the use of the current gas/oil fired boilers at the installation </label>
              </div>

            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="reduce_demand" type="checkbox" (change)="updateExpanded($event, ['evalCriteriaCHPReduceDemand'])" [(ngModel)]="nooData.has_reduce_demand_utility" trueFalseValue trueValue="Reducing the electrical demand and therefore the cost of electricity from the local utility provider" falseValue="undefined"/>Reducing
                  the electrical demand and therefore the cost of electricity from the local utility provider </label>
              </div>

            </div>
          </div>
        </div>
<!--        <div class="form-group">-->
<!--          <div class="row">-->
<!--            <div class="col-sm-12">-->
<!--              <div class="checkbox">-->
<!--                <label>-->
<!--                  <input id="reduce_greenhouse_emissions" (change)="updateExpanded($event, ['evalCriteriaCHPReduceGreenHouse'])" type="checkbox" trueFalseValue trueValue="Reduction of facility greenhouse gas emission levels" falseValue="undefined"-->
<!--                         [(ngModel)]="nooData.has_reduce_greenhouse_emissions"/>Reduction of facility greenhouse gas emission-->
<!--                  levels </label>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="lower_cost" type="checkbox" (change)="updateExpanded($event, ['evalCriteriaCHPLowerCost'])" [(ngModel)]="nooData.has_lower_cost" trueFalseValue trueValue="Generating electricity and/or thermal energy from the CHP plant at a lower cost than current cost" falseValue="undefined"/>Generating
                  electricity and/or thermal energy from the CHP plant at a lower cost than current cost </label>
              </div>

            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="other_specific_chp" type="checkbox" (change)="updateExpanded($event, ['evalCriteriaCHPOther'])"
                         [(ngModel)]="nooData.has_other_specific_chp_writein"/>Other (write-in) </label>
              </div>
              <div class="row" *ngIf="nooData.has_other_specific_chp_writein">
                <div class="col-sm-12">
                  <textarea rows="4" class="form-control" id="other_specific_chp_textarea" name="other_specific_chp_textarea"
                            [(ngModel)]="nooData.other_specific_chp_textarea"></textarea>
                </div>

            </div>
          </div>
        </div>

          <div class="row">
            <div class="col-sm-9">
              <label for="evaluationChpSpecifics">Select Evaluation Criteria:</label>
              <select class="form-control" size="5" id="evaluationChpSpecifics" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.evaluationChp">
                <option *ngFor= "let evaluationChp of evaluationChps " [ngValue]="evaluationChp" > {{evaluationChp.label}} </option>
               </select></div>
            <div class="col-sm-3" *ngIf="nooData.evaluationChp.indexOf(evaluationChps[4]) > -1">
              <label for="other_chp_eval_criteria">Other Eval Criteria:</label>
              <textarea class="form-control" id="other_chp_eval_criteria" name="other_chp_eval_criteria" [(ngModel)]="nooData.other_chp_eval_criteria"></textarea>
            </div>
          </div>
      </div>
      </span>

      <p><strong>3.2.6 Water Conservation Project Objectives&nbsp;&nbsp;</strong><button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#wcpo">&nbsp;&raquo;&nbsp;</button></p>


      <span class="collapse" id="wcpo">
        <p>Check a box for a water conservation objective to:</p>
          <ol>
            <li>add the objective to the NOO, and</li>
            <li>add the related suggested evaluation criterion to the evaluation forms.</li>
          </ol>
      <div class="form-group">
        <div class="row">
          <label for="other_water_goal_textarea" class="col-sm-2 control-label">Write in specific water conservation
            objective:</label>

          <div class="col-sm-10">
        <textarea rows="4" class="form-control" id="other_water_goal_textarea" name="other_water_goal_textarea"
                  [(ngModel)]="nooData.other_water_goal_textarea"></textarea>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-sm-12">
            <div class="checkbox">
              <label>
                <input id="major_water" type="checkbox" [(ngModel)]="nooData.has_major_water" trueFalseValue trueValue="Implement water conservation projects that include major water consuming equipment and processes" falseValue="undefined"/>Implement
                water conservation projects that include major water consuming equipment and processes </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-sm-12">
            <div class="checkbox">
              <label>
                <input id="water_balance" type="checkbox" [(ngModel)]="nooData.has_water_balance" trueFalseValue trueValue="Develop site level water balance to provide annual water use breakout of major water consuming equipment and processes" falseValue="undefined"/>Develop
                site level water balance to provide annual water use breakout of major water consuming equipment and
                processes </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-sm-12">
            <div class="checkbox">
              <label>
                <input id="water_source" type="checkbox" [(ngModel)]="nooData.has_water_source" trueFalseValue trueValue="Access alternative sources of water to offset use of freshwater (e.g. harvested rainwater, AHU condensate, reclaimed wastewater)" falseValue="undefined"/>Access
                alternative sources of water to offset use of freshwater (e.g. harvested rainwater, AHU condensate,
                reclaimed wastewater) </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-sm-12">
            <div class="checkbox">
              <label>
                <input id="use_has_water_use" type="checkbox" [(ngModel)]="nooData.use_has_water_use" (change)="updateSelected($event, 'has_water_use', 'use_has_water_use')"/>Reduce site level potable water use by
                <input type="text" id="water_potable" [(ngModel)]="nooData.water_potable_value" (blur)="updateSelected($event, 'has_water_use', 'use_has_water_use')"> </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-sm-12">
            <div class="checkbox">
              <label> <input id="use_has_non_potable_water_use" type="checkbox"
                             [(ngModel)]="nooData.use_has_non_potable_water_use" (change)="updateSelected($event, 'has_non_potable_water_use', 'use_has_non_potable_water_use')"/>Reduce
                site level non-potable water use by
                <input type="text" id="water_non_potable" [(ngModel)]="nooData.water_non_potable_value" (blur)="updateSelected($event, 'has_non_potable_water_use', 'use_has_non_potable_water_use')"> </label>
            </div>
          </div>
        </div>
      </div>


      <div class="form-group">
        <div class="row">
          <div class="col-sm-12">
            <div class="checkbox">
              <label>
                <input id="other_water_objective" type="checkbox" [(ngModel)]="nooData.has_other_water_objective_writein"/>Other
                (write-in) </label>
            </div>

            <div class="row" *ngIf="nooData.has_other_water_objective_writein">

              <div class="col-sm-12">
                <textarea rows="4" class="form-control" id="other_water_objective_textarea" name="other_water_objective_textarea"
                [(ngModel)]="nooData.other_water_objective_textarea"></textarea>
              </div>

          </div>

          </div>
          </div>
        </div>

        <div class="form-group">
                <div class="row">
                  <div class="col-sm-9">
                    <label for="evaluationWaterSpecifics">Select Evaluation Criteria:</label>
                    <select class="form-control" size="4" id="evaluationWaterSpecifics" multiple ng-multiple="true" style="word-wrap: break-word;" [(ngModel)]="nooData.evaluationWater">
                      <option *ngFor= "let evaluationWater of evaluationWaters " [ngValue]="evaluationWater" > {{evaluationWater.label}} </option>
                    </select>
                  </div>
                  <div class="col-sm-3" *ngIf="nooData.evaluationWater.indexOf(evaluationWaters[5]) > -1">
                    <label for="other_water_eval_criteria">Other Eval Criteria:</label>
                    <textarea rows="4" class="form-control" id="other_water_eval_criteria" name="other_water_eval_criteria" [(ngModel)]="nooData.other_water_eval_criteria"></textarea>
                  </div>
                </div>
        </div>
      </span>

      <p><strong>3.2.7 ESA Information and Project Objectives &nbsp;&nbsp;</strong><button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#eipo">&nbsp;&raquo;&nbsp;</button></p>
      <span class="collapse" id="eipo">

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label >
                  <input id="use_has_Objectives_ESA" type="checkbox" [(ngModel)]="nooData.use_has_Objectives_ESA" (change)="updateSelected($event, 'has_Objectives_ESA', 'use_has_Objectives_ESA')"/> Objectives for ESA
                  <textarea rows="4" class="form-control" id="Objectives_ESA_textarea" name="Objectives_ESA_textarea"
                              [(ngModel)]="nooData.Objectives_ESA_textarea" (blur) = "updateSelected($event, 'has_Objectives_ESA', 'use_has_Objectives_ESA')"></textarea>
                  </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="use_has_system_size" type="checkbox" [(ngModel)]="nooData.use_has_system_size" (change)="updateSelected($event, 'has_system_size', 'use_has_system_size')"/> Estimated System Size
                  <input type="text" id="system_size" [(ngModel)]="nooData.system_size_value" (blur)="updateSelected($event, 'has_system_size', 'use_has_system_size')"> </label>
              </div>
            </div>
          </div>
        </div>


        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="use_has_system_type" type="checkbox" [(ngModel)]="nooData.use_has_system_type" (change)="updateSelected($event, 'has_system_type', 'use_has_system_type')"/> System Type
                  <input type="text" id="system_type" [(ngModel)]="nooData.system_type_value" (blur)="updateSelected($event, 'has_system_type', 'use_has_system_type')"> </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <div class="checkbox">
                <label>
                  <input id="use_has_area_available" type="checkbox" [(ngModel)]="nooData.use_has_area_available" (change)="updateSelected($event, 'has_area_available', 'use_has_area_available')"/> Area Available
                  <input type="text" id="area_available" [(ngModel)]="nooData.area_available_value" (blur)="updateSelected($event, 'has_area_available', 'use_has_area_available')"> </label>
              </div>
            </div>
          </div>
        </div>

      </span>

      </div>
    </div>
  </div>





















          <!--Modal Text-->

  <jw-modal id="statementOfObjectivesModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog wide">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button " class="close" (click)="closeMODAL('statementOfObjectivesModal')"><span aria-hidden="true">&times;</span> <span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idStatementOfObjectivesModal">Statement of Objectives</h4>
        </div>
        <div class="modal-body">
          <p>
            — <strong><em>Section 3.1 General Statement of Objectives</em></strong> contains a broad statement of project objectives.
          </p>
          <p class="col-sm-11 col-sm-offset-1"><strong>Define Requirements Broadly (Sect 3.1).</strong>  FEMP recommends that the NOO define requirements broadly to give ESCOs latitude to consider all viable energy and water improvements. The ESCOs should understand that for source selection purposes the potential requirement includes the entire facility and all viable ECMs.</p>
          <p>
            — <strong><em>Section 3.2 Puts the Focus on High-Priority Agency Project Objectives and Goals.</em></strong>.
          </p>
          <p class="col-sm-11 col-sm-offset-1">The agency should identify two or three high-priority project objectives, goals, opportunities, challenges, or ECMs in 3.2.  Informing ESCOs of the agency’s top priorities can elicit information that will allow agencies to distinguish between ESCOs in terms of these priorities.</p>
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" (click)="closeMODAL('statementOfObjectivesModal')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>


  <jw-modal id="moreSoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog wide">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button "  class="close" (click)="closeMODAL('moreSoModal')"><span aria-hidden="true">&times;</span> <span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idmoreSoModal">Learn more about objectives and matching evaluation criteria.</h4>
        </div>
        <div class="modal-body">
          <p>
            <strong>Objectives stated in section 3 are carried through the NOO process in two important ways:</strong>
          </p>
          <ol>
            <li>The <u>objectives</u> entered in section 3 are incorporated into the required ESCO submittals <em>by reference</em> (section 5).</li>
            <li>The specific, high-priority objectives entered by the user in section 3.2 have matching suggested evaluation criteria to be entered into the <em>NOO-Response-Evaluation form</em>.</li>
            <li>These selections should be limited to no more than 5, as more may not be better just harder to evaluate.</li>
          </ol>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-lg" (click)="closeMODAL('moreSoModal')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>



