import { nooDataDefault } from './../shared/NooData';
import { NooDataService } from './../services/noo-data.service';
import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-notice-and-invitation',
  templateUrl: './notice-and-invitation.component.html',
  styleUrls: ['./notice-and-invitation.component.scss']
})
export class NoticeAndInvitationComponent implements OnInit {
  nooData: any = nooDataDefault;

  constructor(private nooDataService: NooDataService) {}

  ngOnInit(): void {

    this.nooDataService.getValue().subscribe((value) => {
      this.nooData = value;
      console.log(this.nooData);
    });
  }

  formChanged() {
    this.nooDataService.setValue(this.nooData); // set the current form values to match the nooData object
  }

  updateSelection(event, id, parentId){
    let inText: string;
    switch (id) {
      case 'multiple_to_text':
          if (this.nooData.agency_name) {
            inText = 'Multiple task orders. ' + this.nooData.agency_name + ' reserves the right to determine whether this requirement will result in one task order or multiple task orders.';
          } else {
            inText = 'Multiple task orders. Agency Name reserves the right to determine whether this requirement will result in one task order or multiple task orders.';
          }
          break;

      default:
      inText = 'undefined';
      break;

    }

    if (event.target.checked) {
      this.nooData[id] = inText;
      this.nooDataService.setValue(this.nooData);
    }

    if (!event.target.checked) {
      this.nooData[id] = '';
    }

  }

}
