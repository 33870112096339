import { Component, OnInit } from '@angular/core';
import {nooDataDefault} from '../shared/NooData';
import { ModalService } from '../_modal';
import { NooDataService } from './../services/noo-data.service';


@Component({
  selector: 'app-esco-selection',
  templateUrl: './esco-selection.component.html',
  styleUrls: ['./esco-selection.component.scss']
})
export class EscoSelectionComponent implements OnInit {
  nooData: any = nooDataDefault;


  constructor(private modalService: ModalService, private nooDataService: NooDataService) { }

  ngOnInit() {
    this.nooDataService.getValue().subscribe((value) => {
      this.nooData = value;
    });

  }
  formChanged() {
    this.nooDataService.setValue(this.nooData); // set the current form values to match the nooData object
  }
  open(id: string) {
    this.modalService.open(id);
}

close(id: string) {
    this.modalService.close(id);
}

updateSelection(event, id, parentId, value) {
  let inText: string;
  switch (id) {
    case 'single_esco_text':
      inText = 'The agency will select one ESCO to conduct a preliminary assessment (PA).';
      break;

    case 'multiple_projects_text':
      inText = 'This notice includes several anticipated agency projects, and multiple awards are anticipated. Multiple ESCOs may be selected to conduct PAs for multiple projects.';
      break;

    default:
      inText = 'undefined';
      break;
  }
  if (event.target.checked) {
    this.nooData[id] = inText;
    this.nooDataService.setValue(this.nooData);
  }

  if (!event.target.checked) {
   // let index = this.nooData[id].indexOf(id);
  // if (index > -1) {
    // this.nooData.splice(index, 1);
    this.nooData[id] = '';

    // }
   }
  console.log(this.nooData);

}

}
