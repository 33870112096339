export const energyEcms = [
    {value: 0, label: 'Combined heat and power (CHP)'},
    {value: 1, label: 'Wind'},
    {value: 2, label: 'PV generation'},
    {value: 3, label: 'PV water heating'},
    {value: 4, label: 'Thermal solar'},
    {value: 5, label: 'Ground-source heat pumps (GHP)'},
    {value: 6, label: 'Biomass/alternative fuels'},
    {value: 7, label: 'Thermal storage'},
    {value: 8, label: 'Daylighting'},
    {value: 9, label: 'Advanced boilers'},
    {value: 10, label: 'Cool roofs'},
    {value: 11, label: 'IDIQ Technology Categories: '}
  ];

