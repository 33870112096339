import { Component, OnInit } from '@angular/core';
import {nooDataDefault} from '../shared/NooData';
import {evaluationWaters } from './../shared/evaluationWaters';
import {generalObjectives} from '../shared/generalObjectives';
import { energyEcms } from './../shared/energyEcms';
import { waterEcms } from './../shared/waterEcms';
import { idiqEcms } from './../shared/idiqEcms';
import { esros } from './../shared/esros';
import { evaluationCHPProjectObjectives } from './../shared/evaluationCHPProjectObjectives';
import { evaluationRESProjectObjectives } from './../shared/evaluationRESProjectObjectives';
import { evaluationEcmSpecifics } from './../shared/evaluationEcmSpecifics';
import { ModalService } from '../_modal';
import { NooDataService } from './../services/noo-data.service';



@Component({
  selector: 'app-objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.scss']
})
export class ObjectivesComponent implements OnInit {



  nooData: any = nooDataDefault;
  evaluationRes = evaluationRESProjectObjectives;
  evaluationChps = evaluationCHPProjectObjectives;
  evaluationWaters = evaluationWaters;
  generalObjectives = generalObjectives;
  energyEcms = energyEcms;
  waterEcms = waterEcms;
  idiqEcms = idiqEcms;
  esros = esros;
  evaluationEcmSpecifics = evaluationEcmSpecifics;




constructor(private modalService: ModalService, private nooDataService: NooDataService) { }

ngOnInit() {

    this.nooDataService.getValue().subscribe((value) => {
    this.nooData = value;
  });

}

formChanged() {
  this.nooDataService.setValue(this.nooData); // set the current form values to match the nooData object
}
openMODAL(id: string) {
  this.modalService.open(id);
}

closeMODAL(id: string) {
  this.modalService.close(id);
}

onCheckbox(event, value) {

  if (event.target.checked) {

    this.nooData.generalObjectives.push(value);
    this.nooDataService.setValue(this.nooData);
  }

  if (!event.target.checked) {
    const index = this.nooData.generalObjectives.indexOf(value);
    if (index > -1) {
      this.nooData.generalObjectives.splice(index, 1);
      this.nooDataService.setValue(this.nooData);
    } }
}





clearRelated(event, value){
  if (!event.target.checked) {
    const index = 0;
    if (index > -1) {
      this.nooData.boilerplateStatementOfObjectives = '';
      this.nooDataService.setValue(this.nooData);
  }

}
}

updateSelected(event, id, parentId) {
  let inText: string;
  switch (id) {
    case 'boilerplateStatementOfObjectives':
        if (this.nooData.facility_name) {
          inText = 'The project will accomplish significant reductions at ' + this.nooData.facility_name + ' in energy and water use, energy, water and O&M costs, and enhance energy and water resilience by implementing energy and water conservation measures (ECMs) as listed in attachment J-3 of the Fourth Generation DOE ESPC IDIQ contract. The ESCO shall explore and recommend all ECMs that may be economically viable and will contribute to the overall project goals and objectives.The ESCO should reduce project costs and increase project benefits by leveraging available direct funding; identifying and supporting agency application for available utility rebates; local, state, and regional financial incentives; renewable energy certificates (RECs); and other investment or tax credits and supporting agency application for AFFECT and other available federal funding.';
        } else {
          inText = 'The project will accomplish significant reductions at Facility Name in energy and water use, energy, water and O&M costs, and enhance energy and water resilience by implementing energy and water conservation measures (ECMs) as listed in attachment J-3 of the Fourth Generation DOE ESPC IDIQ contract. The ESCO shall explore and recommend all ECMs that may be economically viable and will contribute to the overall project goals and objectives.The ESCO should reduce project costs and increase project benefits by leveraging available direct funding; identifying and supporting agency application for available utility rebates; local, state, and regional financial incentives; renewable energy certificates (RECs); and other investment or tax credits and supporting agency application for AFFECT and other available federal funding.';
        }
        break;
    case 'has_specific_level':
        if (this.nooData.specific_savings_value) {
          inText = 'Achieve specific level of energy/water/cost savings ' + this.nooData.specific_savings_value;
        } else {
          inText = 'Achieve specific level of energy/water/cost savings [specify savings here]';
        }
        break;
    case 'has_decrease_intensity':
        if (this.nooData.intensity_decrease_value) {
          inText = 'Decrease energy intensity by ' + this.nooData.intensity_decrease_value;
        } else {
          inText = 'Decrease energy intensity by [specify intensity here]';
        }
        break;

    case 'has_decrease_water_consumption':
          if (this.nooData.water_consumption_decrease_value) {
            inText = 'Decrease water consumption by ' + this.nooData.water_consumption_decrease_value;
          } else {
            inText = 'Decrease water consumption by [specify value here]';
          }
          break;
    case 'has_outage_solutions':
          if (this.nooData.outage_solution_value) {
            inText = 'Solutions to address outage duration of ' + this.nooData.outage_solution_value + ' days';
          } else {
          inText = 'Solutions to address outage duration of [specify value here] days';
          }
          break;
    case 'has_availability_level':
          if (this.nooData.availability_level_value) {
            inText = 'Desired system availability level: ' + this.nooData.availability_level_value;
           } else {
          inText = 'Desired system availability level: [specify value here] ';
          }
          break;

    case 'has_rebate_incentive':
          if (this.nooData.applicable_regions) {
            inText = 'Leverage a high-value ESPC using energy rebates and incentives available in ' + this.nooData.applicable_regions + ' and for specific ECMs';
            } else {
          inText = 'Leverage a high-value ESPC using energy rebates and incentives available in [specify regions here] and for specific ECMs';
          }
          break;

    case 'has_chp_plant_installation':
           if (this.nooData.chp_plant_electricity_value) {
             inText = 'Installation of a CHP plant that produces ' + this.nooData.chp_plant_electricity_value + ' MW of electricity ';
          } else {
          inText = 'Installation of a CHP plant that produces [specify amount here] MW of electricity ';
          }
           break;

    case 'has_water_use':
          if (this.nooData.water_potable_value) {
            inText = 'Reduce site level potable water use by ' + this.nooData.water_potable_value;
          } else {
          inText = 'Reduce site level potable water use by [specify usage value here]';
          }
          break;

    case 'has_non_potable_water_use':
          if (this.nooData.water_non_potable_value) {
            inText = 'Reduce site level non-potable water use by ' + this.nooData.water_non_potable_value;
        } else {
          inText = 'Reduce site level non-potable water use by [specify usage value here]';
         }
          break;


    case 'has_Objectives_ESA':
            if (this.nooData.Objectives_ESA_textarea) {
              inText = 'Objectives for ESA: ' + this.nooData.Objectives_ESA_textarea;
          } else {
            inText = 'Objectives for ESA [specify Objectives for ESA here]';
           }
            break;

    case 'has_system_size':
            if (this.nooData.system_size_value) {
              inText = 'Estimated System Size: ' + this.nooData.system_size_value;
          } else {
              inText = 'Estimated System Size [specify Estimated System Size here]';
             }
            break;

    case 'has_system_type':
            if (this.nooData.system_type_value) {
              inText = 'System Type: ' + this.nooData.system_type_value;
           } else {
              inText = 'System Type [specify System Type here]';
              }
            break;

    case 'has_area_available':
            if (this.nooData.area_available_value) {
              inText = 'Area Available: ' + this.nooData.area_available_value;
           } else {
              inText = 'Area Available [specify Area Available here]';
              }
            break;
    default:
      inText = 'undefined';
      break;
  }

  // if (event.target.checked) {
  this.nooData[id] = inText;
  this.nooDataService.setValue(this.nooData);
  // }

  // if (!event.target.checked) {
   // this.nooData[id] = '';
   // let index = this.nooData[id].indexOf(id);
  // if (index > -1) {
   // this.nooData.splice(index, 1);

    // }
 // };
  console.log(this.nooData);

}

updateExpanded($event, ids) {
  const checkbox = $event.target;
  const action = (checkbox.checked ? 'leave' : 'remove');
  if (action === 'remove') {
    for (const item of ids) {
      if (this.nooData.hasOwnProperty(item)) {
        this.nooData[item] = [];
      }

    }
  }
}



}
