import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import { NooDataService } from './../services/noo-data.service';
import { OnInit } from '@angular/core';


function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
export class Document implements OnInit {
  nooData: any;

  constructor(private nooDataService: NooDataService){}
  ngOnInit(): void {
    this.nooDataService.getValue().subscribe((value) => {
      this.nooData = value;
    });
  }

  populateNOOTemplate(data, hasEsa) {
    // this.generatePopulatedTemplate(data, 'assets/docs/trivial.docx', 'trivial1.docx');
    if (hasEsa) {
    this.generatePopulatedTemplate(data, 'assets/docs/NOO_Template_Populated_ESA.docx', 'NOO_Template_Populated_ESA.docx');
    } else {
       this.generatePopulatedTemplate(data, 'assets/docs/NOO_Template_Populated.docx', 'NOO_Template_Populated.docx');
    }
  }

  nooTemplate() {
    this.generateTemplate({}, 'assets/docs/NOO_Template.docx', 'NOO_Template.docx');
  }


  responseEvalIndividual(data) {
    this.generatePopulatedTemplate(data, 'assets/docs/Eval Form-1-Indiv.docx', 'Evaluation Template Individual.docx');
  }

  responseEvalConsensus(data) {
    this.generatePopulatedTemplate(data, 'assets/docs/Eval Form-2-Consensus.docx', 'Evaluation Template Consensus.docx');
  }

  responseEvalDefinitions(data) {
    this.generateTemplate(data, 'assets/docs/Evaluation Forms Instructions-Definitions.docx', 'Evaluation Templates Instructions-Definitions.docx');
  }
  responseEvalSummary(data) {
    this.generateTemplate(data, 'assets/docs/Eval Form 3-Summary.docx', 'Evaluation Templates Summary.docx');
  }



  generatePopulatedTemplate(data, template, outputName) {

    loadFile(template, (err, content) => {
      if (err) { throw err; }
      const zip = new PizZip(content);
      const doc = new Docxtemplater().loadZip(zip);
      // Task is to load the doc.setData with all the variable values either by using the data somehow directly
      // This fails somehow could be odd characters in the data
      doc.setData(data);
      doc.render();
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }); // Output the document using Data-URI
      saveAs(out, outputName);
    });
  }


  generateTemplate = (data, template, outputName) => {
    loadFile(template, (err, content) => {
      if (err) { throw err; }
      const zip = new PizZip(content);
      const doc = new Docxtemplater().loadZip(zip);
      doc.render();
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      });
      saveAs(out, outputName);
    });
  }

}

